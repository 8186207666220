.Notice {
    text-align: center;
  }
  
  .box {
    position: absolute;
    cursor: move;
    color: black;
    background-color: white;
    max-width: 215px;
    overflow-wrap: break-word;
    border-radius: 5px;
    padding: 1em;
    margin: auto;
    user-select: none;
  }
  
  .Notice {
    
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
  }
  #new-item {
    user-select: none;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #new-item input {
    border-radius: 5px 0px 0px 5px;
    color: black;
    font-size: 0.9em;
    background-color: transparent;
    padding: 0.5em;
  }
  
  #new-item input:focus {
    outline: none;
  }
  
  #new-item button {
    cursor: pointer;
    margin-left: 5px !important;
    background-color: rgb(241, 114, 114);
    font-size: 0.9em;
    padding: 0.5em;
    border-radius: 5px;
  }
  
  #new-item button:hover {
    background-color: rgb(255, 126, 126);
  }
  
  #delete {
    font-size: 0.6em;
    position: fixed;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
  }
  
  #delete:hover {
    color: rgb(255, 55, 55);
  }